import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes, useLocation } from 'react-router-dom';
import axios from 'axios';
import Inicio from './pages/Inicio';
import Error404 from './components/Error404';
import Header from './components/Header';
import Footer from './components/Footer';
import IniciarSesion from './pages/IniciarSesion';
import Dashboard from './pages/usuario/Dashboard';
import Loader from './components/Loader';
import NuevoTicket from './pages/usuario/NuevoTicket';
import TicketCreado from './pages/usuario/TicketCreado';
import Ticket from './pages/usuario/Ticket';
import Servicios from './pages/usuario/Servicios';
import TicketManager from './pages/usuario/admin/TicketManager';
import UserManager from './pages/usuario/admin/UserManager';
import EmpiezaAhora from './pages/usuario/EmpiezaAhora';
import BASE_URL from './config/config';
import Versiones from './pages/Versiones';
import RecuperarContrasena from './pages/RecuperarContrasena';
import EditarContrasena from './pages/EditarContrasena';

const Routering = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verificarToken = async () => {
      try {
        const token = localStorage.getItem('AuthToken');
        if (!token) {
          throw new Error('No se encontró token');
        }

        const response = await axios.get(`${BASE_URL}/autenticacion`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        console.log('Respuesta exitosa:', response.data);
        setAuthenticated(true);
      } catch (error) {
        console.error('Error al verificar el token:', error);
        setAuthenticated(false);
        localStorage.removeItem('AuthToken');
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };

    verificarToken();

    const handleStorageChange = (event) => {
      if (event.key === 'AuthToken') {
        verificarToken();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Router>
      <AppContent authenticated={authenticated} loading={loading} />
    </Router>
  );
};

const AppContent = ({ authenticated, loading }) => {
  const [routeLoading, setRouteLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setRouteLoading(true);
    const timeoutId = setTimeout(() => {
      setRouteLoading(false);
    }, 500); // Aquí puedes ajustar el tiempo que se muestra el Loader.

    return () => clearTimeout(timeoutId);
  }, [location]);

  return (
    <>
      <Header authenticated={authenticated} />
      {loading || routeLoading ? (
        <Loader />
      ) : (
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/registrarse" element={<EmpiezaAhora />} />
          <Route path="/recuperar-contrasena" element={<RecuperarContrasena />} />
          <Route path="/editar-contrasena" element={<EditarContrasena />} />
          <Route path="/iniciar-sesion" element={authenticated ? <Navigate to="/dashboard" /> : <IniciarSesion />} />
          <Route path="/dashboard" element={authenticated ? <Dashboard /> : <Navigate to="/iniciar-sesion" />} />
          <Route path="/dashboard/servicios" element={authenticated ? <Servicios /> : <Navigate to="/iniciar-sesion" />} />
          <Route path="/dashboard/tickets-manager" element={authenticated ? <TicketManager /> : <Navigate to="/iniciar-sesion" />} />
          <Route path="/dashboard/users-manager" element={authenticated ? <UserManager /> : <Navigate to="/iniciar-sesion" />} />
          <Route path="/nuevo-ticket" element={authenticated ? <NuevoTicket /> : <Navigate to="/iniciar-sesion" />} />
          <Route path="/ticket-creado/:identificador" element={authenticated ? <TicketCreado /> : <Navigate to="/iniciar-sesion" />} />
          <Route path="/ticket/:identificador" element={authenticated ? <Ticket /> : <Navigate to="/iniciar-sesion" />} />
          <Route path="/iniciar-sesion" element={authenticated ? <Navigate to="/dashboard" /> : <IniciarSesion />} />
          <Route path="/version" element={authenticated ? <Versiones /> : <Navigate to="/iniciar-sesion" />} />

          <Route path="*" element={<Error404 />} />
        </Routes>
      )}
      <Footer />
    </>
  );
};

export default Routering;
