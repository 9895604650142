import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js'; // Importar la biblioteca crypto-js
import BASE_URL from '../config/config';

const IniciarSesion = () => {
  const [usuario, setUsuario] = useState('');
  const [contrasena, setContrasena] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleUsuarioChange = (event) => {
    // Convertir el usuario a minúsculas
    setUsuario(event.target.value.toLowerCase());
  };

  const handleContrasenaChange = (event) => {
    setContrasena(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      // Encriptar la contraseña en MD5 antes de enviarla
      const contrasenaEncriptada = CryptoJS.MD5(contrasena).toString();

      const response = await axios.post(`${BASE_URL}/login`, { 
        usuario, 
        contrasena: contrasenaEncriptada // Enviar la contraseña encriptada
      });
      
      if (response.status === 200) {
        // Iniciar sesión exitosa, redirigir al usuario
        console.log(response.data.jwt_token);
        localStorage.setItem("AuthToken", response.data.jwt_token);
        window.location.reload();
      } else {
        // Inicio de sesión fallido, mostrar mensaje de error
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      setError('Error al iniciar sesión. Por favor, inténtalo de nuevo.');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <main className='inicia-sesion site-width'>
      <div className='formulario-inicio'>
        <h1>Inicia sesión</h1>
        <p>¡Nos alegra tenerte de vuelta! Entra y comparte con nosotros tu tarea/incidencia.</p>
        <div className='campos-inicio'>
          <div className="campo-inicio">
            <label htmlFor="user">Usuario</label>
            <input autoComplete="off" type="text" id="user" value={usuario} onChange={handleUsuarioChange} placeholder='Escribe aquí tu usuario...' />
          </div>
          <div className="campo-inicio">
            <label htmlFor='password'>Contraseña</label>
            <input autoComplete="off" type="password" name="password" value={contrasena} onChange={handleContrasenaChange} id="password" placeholder='Escribe aquí tu contraseña...' onKeyDown={handleKeyDown} />
          </div> 
          <p className='lost-password'>¿Has olvidado tu contraseña? <Link to={'/recuperar-contrasena'}>Solicita una nueva</Link></p>
          {error && <p className="error">{error}</p>}
          <button type="button" onClick={handleSubmit}>Acceder a mi cuenta</button>
        </div>
      </div>
    </main>
  );
};

export default IniciarSesion;
