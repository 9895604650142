import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // jwtDecode se usa sin llaves
import BASE_URL from '../config/config';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isExpired, setIsExpired] = useState(false); // Estado para verificar caducidad
  const [decoded, setDecoded] = useState(null); // Guardar el token decodificado

  // Capturar el token desde la URL
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token'); // Extraer el token

  // Decodificar el token para extraer el nombre o correo del usuario
  useEffect(() => {
    try {
      if (token) {
        const decodedToken = jwtDecode(token); // Decodifica el token
        setDecoded(decodedToken); // Guarda el token decodificado
        const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos (UNIX Epoch)
        if (decodedToken.exp < currentTime) {
          setIsExpired(true); // Si ha caducado, actualiza el estado
          setError('El enlace ha caducado.');
        }
      }
    } catch (err) {
      console.error('Error al decodificar el token:', err);
      setError('El enlace es inválido o ha expirado.');
    }
  }, [token]);

  const handleSubmit = async () => {
    // Validación de contraseñas
    if (!newPassword || newPassword.length < 5) {
      setError('La contraseña debe tener al menos 5 caracteres.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    if (isExpired) {
      setError('El enlace ha caducado. Solicita uno nuevo.');
      return;
    }

    if (!decoded) {
      setError('El token no es válido. Solicita un nuevo enlace.');
      return;
    }

    try {
      // Enviar la nueva contraseña y el nombre del usuario extraído al backend
      const response = await axios.post(`${BASE_URL}/restaurar-contrasena`, {
        token, // Enviar el token completo por si el backend necesita verificarlo
        newPassword,
        usuario: decoded.username, // Extraer el correo (o nombre) desde el token decodificado
      });

      if (response.status === 200) {
        setMessage('Contraseña restablecida con éxito. Ahora puedes iniciar sesión.');
        setError('');
      }
    } catch (error) {
      console.error('Error al restablecer la contraseña:', error);
      setError(error.response?.data?.message || 'Error al restablecer la contraseña.');
    }
  };

  return (
    <main className="inicia-sesion site-width">
      <div className="formulario-inicio">
        <h1>Restablecer Contraseña</h1>
        {isExpired ? (
          <p className="error">{error}</p>
        ) : (
          <>
            <p>Introduce tu nueva contraseña y confírmala para actualizar tu cuenta.</p>
            <div className="campos-inicio">
              <div className="campo-inicio">
                <label htmlFor="new-password">Nueva Contraseña</label>
                <input
                  type="password"
                  id="new-password"
                  placeholder="Introduce tu nueva contraseña"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="campo-inicio">
                <label htmlFor="confirm-password">Confirma tu Contraseña</label>
                <input
                  type="password"
                  id="confirm-password"
                  placeholder="Confirma tu nueva contraseña"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              {message && <p className="success">{message}</p>}
              {error && <p className="error">{error}</p>}
              <button type="button" onClick={handleSubmit}>
                Restablecer Contraseña
              </button>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default ResetPassword;
