import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BASE_URL from '../config/config';

const RecuperarContrasena = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [mensajeBoton, setMensajeBoton] = useState('Enviar enlace de recuperación');
  const [disabled, setDisabled] = useState(false); // Estado para manejar el cooldown del botón
  const [cooldown, setCooldown] = useState(0); // Estado para la cuenta atrás

  const handleEmailChange = (event) => {
    setEmail(event.target.value.toLowerCase());
  };

  const handleSubmit = async () => {
    if (disabled) return; // Si el botón está deshabilitado, no hace nada

    setMensajeBoton('Enviando...');
    setCooldown(30); // Establecer el cooldown inicial a 30 segundos
    try {
      const response = await axios.post(`${BASE_URL}/recuperar-contrasena`, { email });

      if (response.status === 200) {
        setMessage('Se ha enviado un enlace de recuperación a tu correo electrónico.');
        setError('');
        setDisabled(true); // Deshabilitar el botón
      } else {
        setError(response.data.message || 'Ocurrió un error al enviar el enlace.');
        setMessage('');
      }
    } catch (error) {
      console.error('Error al recuperar la contraseña:', error);
      setError('Error al enviar el enlace de recuperación. Por favor, inténtalo más tarde.');
      setMessage('');
    } finally {
      setMensajeBoton('Enviar enlace de recuperación');
    }
  };

  // Manejar la cuenta atrás
  useEffect(() => {
    let timer;
    if (cooldown > 0) {
      timer = setTimeout(() => {
        setCooldown((prev) => prev - 1);
      }, 1000); // Reducir el contador cada segundo
    } else if (cooldown === 0 && disabled) {
      setDisabled(false); // Reactivar el botón cuando el cooldown llegue a 0
    }
    return () => clearTimeout(timer); // Limpiar el timer al desmontar el componente o al cambiar el estado
  }, [cooldown, disabled]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !disabled) {
      handleSubmit();
    }
  };

  return (
    <main className="inicia-sesion site-width">
      <div className="formulario-inicio">
        <h1>¿Contraseña perdida?</h1>
        <p>Introduce el correo electrónico de tu cuenta y te enviaremos un enlace para resetear la contraseña.</p>
        <div className="campos-inicio">
          <div className="campo-inicio">
            <label htmlFor="email">Correo electrónico</label>
            <input
              autoComplete="off"
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
              placeholder="Correo electrónico asociado a tu cuenta"
            />
          </div>
          {message && <p className="success">{message}</p>}
          {error && <p className="error">{error}</p>}
          <button
            type="button"
            onClick={handleSubmit}
            disabled={disabled} // Deshabilitar el botón cuando esté en cooldown
          >
            {disabled
              ? `Enviar enlace de recuperación (${cooldown}s)` // Mostrar la cuenta atrás en el botón
              : mensajeBoton}
          </button>
        </div>
      </div>
    </main>
  );
};

export default RecuperarContrasena;
