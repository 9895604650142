import React from 'react'

const Versiones = () => {
  return (
    <main className='site-width'>
        <div className="control-versiones">
            <h1>Versión actual <span>v1.0.3</span></h1>
            <div className="bloque-versiones">
            <div className="bloque-version">
                    <p>v1.0.3</p>
                    <ul>
                        <li>Mejoras visuales en móvil.</li>
                        <li>Adjunción de archivos disponibles en mensajes de chat.</li>
                        <li>Galería de archivos de ticket disponible.</li>
                        <li>Método de recuperación de contraseña añadido.</li>
                    </ul>
                </div>
                <div className="bloque-version">
                    <p>v1.0.2</p>
                    <ul>
                        <li>Ahora cuando se verifica una cuenta, el usuario recibe una notificación.</li>
                        <li>Corrección de errores visuales en los tickets.</li>
                        <li>Notificación al técnico mejorada.</li>
                        <li>Optimización y arreglos en la interfaz administrativa.</li>
                        <li>Sistema de sugerencias/mejoras añadido (Canny.io).</li>
                    </ul>
                </div>

                <div className="bloque-version">
                    <p>v1.0.1</p>
                    <ul>
                        <li>Bugs iniciales de la plataforma solucionados.</li>
                    </ul>
                </div>
            </div>
        </div>
       
    </main>
  )
}

export default Versiones
